<template>
  <div></div>
</template>

<script>
import { Toast } from 'vant'
import { getUserInfo,getWaterMarkTxt } from '@/api/wx.js'
import { removeWatermark, setWaterMark } from '@/utils/watermark'
export default {
  data() {
    return {}
  },
  mounted() {
    if(this.$route.query.params != 'isOpenApp_1'){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
    }
    localStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    sessionStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      const loadid = this.$route.query.appLoginId || this.$route.query.langid || 0
      const appCode = this.$route.query.appCode || 0;
      localStorage.setItem('V8AppCode', appCode)
      // console.log(this.$route.query,loadid,'getUserInfo_loadidloadidloadid')
      localStorage.setItem('langid', loadid)
      getUserInfo({ usertoken: this.$route.query.usertoken,langid:loadid }).then(res => {
        if(res && res.data && res.data.length > 0){
          localStorage.setItem('userInfo', JSON.stringify(res.data[0]))
          sessionStorage.setItem('userInfo', JSON.stringify(res.data[0]))
          localStorage.setItem('v8_login_pwd', res.data[0].pwd)
          localStorage.setItem('v8_login_username', res.data[0].username)
          //单点登录后跳转旧版界面用
          try {
            SetUserLocal(
              window.CloundEntid,
              window.CloundEntid,
              res.data[0].empcode,
              res.data[0].empname,
              res.data[0].username,
              res.data[0].v8_login_pwd,
              res.data[0].empname,
              0,
              res.data[0].empid
            )
            setLocVal('spusername', res.data[0].username)
          } catch (error) {}
          getWaterMarkTxt({
            username:res.data[0].username
          }).then(res=>{
            const ressy = res.data;
            if(ressy && ressy.length > 0){
              const syobj = ressy[0];
              if(syobj.show_watermark == 1){
                localStorage.setItem("sysShuiYin",JSON.stringify(syobj))
                setWaterMark(
                  syobj.watermark_line1,
                  syobj.watermark_line2,
                  syobj.watermark_line3
                )
              }else{
                localStorage.removeItem("sysShuiYin")
              }
            }
            if(this.$route.query.params != 'isOpenApp_1'){
              Toast.clear()
            }else{
              var data = {
                isLoadingAll: true
              }
              if(/android/i.test(navigator.userAgent)){
                //这是Android平台下浏览器
                window.app.sendData(JSON.stringify(data));   
              }else{   
                uni.postMessage({
                  data: data
                })
              } 
            }
            this.goto()
          })
        }else{
          var msg = "访问出错，当前的userToken："+this.$route.query.usertoken
          if(confirm(msg) == true){
            if (this.$route.query.params == 'isOpenApp_1') {
              var data = {
                outLogin: true
              }
              if (/android/i.test(navigator.userAgent)) {
                //这是Android平台下浏览器
                window.app.sendData(JSON.stringify(data))
              } else {
                uni.postMessage({
                  data: data
                })
              }
            }
          }else{
            if (this.$route.query.params == 'isOpenApp_1') {
              var data = {
                outLogin: true
              }
              if (/android/i.test(navigator.userAgent)) {
                //这是Android平台下浏览器
                window.app.sendData(JSON.stringify(data))
              } else {
                uni.postMessage({
                  data: data
                })
              }
            }
          }
        }
        
      }).catch((err)=>{
        var msg = "访问出错，当前的userToken："+this.$route.query.usertoken
        if(confirm(msg) == true){
          if (this.$route.query.params == 'isOpenApp_1') {
            var data = {
              outLogin: true
            }
            if (/android/i.test(navigator.userAgent)) {
              //这是Android平台下浏览器
              window.app.sendData(JSON.stringify(data))
            } else {
              uni.postMessage({
                data: data
              })
            }
          }
        }else{
          if (this.$route.query.params == 'isOpenApp_1') {
            var data = {
              outLogin: true
            }
            if (/android/i.test(navigator.userAgent)) {
              //这是Android平台下浏览器
              window.app.sendData(JSON.stringify(data))
            } else {
              uni.postMessage({
                data: data
              })
            }
          }
        }
      })
    },
    getQueryString(name) {
      var result = location.search.match(
        new RegExp('[?&]' + name + '=([^&]+)', 'i')
      )
      if (result == null || result.length < 1) {
        return ''
      }
      return result[1]
    },
    // 跳转
    goto() {
      /*
      http://v8h5.hyclound.cn/AscLogin?usertoken=C6478614104D1178FAD45E54E24D3E80&path=/weixinlocal/queryrpt/yidongqiandaofanwei.html?yyy=1
				parmams格式【参数名称1_参数值1@参数名称2_参数值2】:empid_19%khplanid_12........
      */
      let past = this.$route.query.path // 跳转路由地址
      if (this.$route.query.params) {
        // 传入参数
        const params = this.$route.query.params
        let arr = params.indexOf('@') > -1 ? params.split('@') : [params]
        arr.forEach((e, i) => {
          const dl = i === 0 ? '?' : '&'
          const a = e.split('_')
          past += dl + a[0] + '=' + a[1]
        })
      }
      Toast.clear()
      if (this.$route.query.path.indexOf('/') == 0) {
        //旧版界面必须以/开头
        window.location.href = past
      } else {
        this.$router.replace('/' + past)
      }
    }
  }
}
</script>

<style></style>
